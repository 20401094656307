/* Global styles*/

html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background-color: #f9f9f9;
    overflow-y: scroll;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

/* Material theming */
.mat-chip.mat-standard-chip.tag {
    border-top-left-radius: 6px;
    user-select: none;
}

.hidden {
    display: none !important;
}
