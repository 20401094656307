/**
 * Branding colors. Should be identical to class colors.ts
 */

$kinventBlue: #00456E;
$kinventOrange: #F7941E;

$colorPrimary: $kinventBlue;
$colorAccent: $kinventOrange;

// Orange shades
$kinventOrangeLight: #FDB344;
$kinventOrangeLighter: #FBC88F;

$colorSideLeft: $kinventOrange;
$colorSideRight: $kinventBlue;

$greenLight: #baed91;
$green: #66cc00;
$green2: #43A047;

$red: #cc0000;

.color-primary {
    color: $colorPrimary;
}

.color-accent {
    color: $colorAccent;
}

.bg-primary {
    background-color: $colorPrimary;
}

.bg-accent {
    background-color: $colorAccent;
}

.icon-active {
    color: $green;
}

.icon-inactive {
    color: $red;
}

.icon-expired {
    color: $kinventOrange;
}

$gray-disabled: #707070;

// White transparency. 0 = opaque, 1 = transparent

$white-0: #fff;
$white-10: fade_out(#fff, .1);
$white-20: fade_out(#fff, .2);
$white-30: fade_out(#fff, .3);
$white-40: fade_out(#fff, .4);
$white-50: fade_out(#fff, .5);
$white-60: fade_out(#fff, .6);
$white-70: fade_out(#fff, .7);
$white-80: fade_out(#fff, .8);
$white-90: fade_out(#fff, .9);

$gray-030: darken(#fff, 3%);
$gray-050: darken(#fff, 5%);
$gray-100: darken(#fff, 10%);
$gray-140: darken(#fff, 14%);
$gray-150: darken(#fff, 15%);
$gray-200: darken(#fff, 20%);
$gray-300: darken(#fff, 30%);
$gray-400: darken(#fff, 40%);
$gray-500: darken(#fff, 50%);
$gray-600: darken(#fff, 60%);
$gray-700: darken(#fff, 70%);
$gray-800: darken(#fff, 80%);
$gray-900: darken(#fff, 90%);

$light-blue-022: lighten(lightskyblue, 22%);
$black-54: fade_out(#000, .54);
